import axios from "../utils/axios";

function configToken(token) {
  return { headers: { Authorization: `Bearer ${token}` } }
};

export function getMenus(token) {
  var config = configToken(token);
  return axios.get("/api/menus", config);
}

export function getListaMenu() {
  return axios.get("/api/menus/itens");
}

export function inserirMenu(data) {
  return axios.post("/api/menus", data);
}

export function habilitarItemMenu(data) {
  return axios.post("/api/menus/habilitar", data);
}