import React from "react";
import styled from "@emotion/styled";
import LogoHzero from '../images/logo_hzero_PB.png';
import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
  bottom: 0;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: flex;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
    margin-right: 10px;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            <ListItemButton
              className="footer-button"
              target="_blank"
              component="a"
              to="https://hzero.youtrack.cloud/form/358559d0-b7ba-449c-81cd-d43bd393dea6"
              sx={{ width: 'fit-content' }}
            >
              <ListItemText primary="Suporte" />
            </ListItemButton>
            {/* <ListItemButton component="a" href="#">
              <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Help Center" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Privacy" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Terms of Service" />
            </ListItemButton> */}
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton component={NavLink} to="https://hzero.com.br/">
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Desenvolvido por`}
              />
              <img src={LogoHzero} style={{ height: '18px' }} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
