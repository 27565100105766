import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../hooks/useAuth";
import SenhaInput from "../forms/Senha/SenhaInput";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          senha: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Digite um email válido")
            .max(255)
            .required("Email é obrigatorio"),
          senha: Yup.string().max(255).required("Senha é obrigatória"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await signIn(values.email, values.senha);
            navigate("/");
            setLoading(false);
          } catch (error) {
            const message = error.mensagem || "Algo deu errado";
            const statusCode = error.statusCode;
            setStatus({ success: false });
            setErrors({ submit: message, code: statusCode });
            setSubmitting(false);
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <SenhaInput
              id="senha"
              name="senha"
              label="Senha"
              value={values.senha}
              error={Boolean(touched.senha && errors.senha)}
              fullWidth
              helperText={touched.senha && errors.senha}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Lembre-me"
            /> */}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{ marginTop: 5 }}
            >
              Entrar
            </LoadingButton>
          </form>
        )}
      </Formik>
      <Button
        component={Link}
        to="/esqueci-minha-senha"
        fullWidth
        color="primary"
      >
        Esqueci minha senha
      </Button>
    </>
  );
}

export default SignIn;