import React from "react";
import LogoImg from '../../images/logo_dark.png';
import { Box } from "@mui/material";

function AuthLogo() {
    return (
        <Box>
            <img
                style={{ width: "100%", padding: "0 40px" }}
                src={LogoImg}
                className="authLogo"
            />
        </Box>
    );
}

export default AuthLogo;