import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import NavbarUserDropdown from "./NavbarUserDropdown";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import Logotipo from '../../images/logo_dark.png'
import { Menu as MenuIcon } from "@mui/icons-material";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            {/* <Grid item sx={{ display: { xs: "block", md: "none" } }}> */}
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <img
                style={{ height: "64px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                src={Logotipo}
              />
            </Grid>
            {/* <Grid item xs /> */}
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);