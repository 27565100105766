import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
    FormControl as MuiFormControl,
    InputAdornment,
    IconButton,
    InputLabel,
    OutlinedInput,
    FormHelperText
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { spacing } from "@mui/system";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

export default function SenhaInput(props) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <FormControl variant="outlined" fullWidth className="password-input">
                <InputLabel htmlFor="senha">{props.label}</InputLabel>
                <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    my={2}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    {...props}
                />
            </FormControl>
        </>
    );
}
