import axios from "../utils/axios";

export function getUsuarios(pagina, registroPagina) {
  return axios.get(`/api/usuarios/paginado?Pagina=${pagina}&RegistroPagina=${registroPagina}`);
}

export function getUsuario(id) {
  return axios.get(`/api/usuarios/${id}`);
}

export function inserirUsuario(data) {
  return axios.post(`/api/usuarios`, data)
    .then(function (response) {
        return response;
    })
    .catch(function (error) {
      throw error;
    });
}

export function atualizarUsuario(id, data) {
  return axios.put(`/api/usuarios/${id}`, data)
    .catch(function (error) {
      throw error;
    });
}

export function deletarUsuario(id) {
  return axios.delete(`/api/usuarios/${id}`);
}

export function getUsuarioLogado() {
  return axios.get(`/api/usuarios/detalhes`);
}

// export function confirmacaoEmailUsuario(email, token) {
//   return axios.get(`/api/usuarios/confirmacao-email?email=${email}&token=${token}`)
//       .then(function (response) {
//           return response.data;
//       })
//       .catch(function (error) {
//           throw error;
//       });
// }

// export function reenviarEmailConfirmacaolUsuario(email) {
//   return axios.post(`/api/usuarios/reenvio-email-confirmacao?email=${email}`, null)
//       .then(function (response) {
//           return response.data;
//       })
//       .catch(function (error) {
//           throw error;
//       });
// }

export function recuperacaoSenhaUsuario(data) {
  return axios.post(`/api/usuarios/recuperacao-senhas?email=${data.email}`, null)
      .then(function (response) {
          return response.data;
      })
      .catch(function (error) {
          throw error;
      });
}

export function alteracaoSenhaUsuario(data, email, token) {
  return axios.post(`/api/usuarios/alteracao-senhas?senha=${data.senha}&email=${email}&token=${token}`);
}