import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import AuthLogo from '../components/logotipo/AuthLogo';
import { CssBaseline } from "@mui/material";
import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";

const Spacer = styled.div(spacing);

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Auth = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <>
        <AuthLogo />
        <Spacer mb={6} />
        <Helmet title="Agroville" />
        {children}
      </>
      <Outlet />
      <Settings />
    </Root>
  );
};

export default Auth;
