import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import createEmotionCache from "./utils/createEmotionCache";
import routes from "./routes";
import { store } from "./redux/store";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import { AuthProvider } from "./contexts/JWTContext";
import "dayjs/locale/pt-br";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();
  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s" defaultTitle="Agrovile" />
        <Provider store={store}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"pt-br"}
          >
            <MuiThemeProvider theme={createTheme(theme)}>
              <AuthProvider>
                {content}
              </AuthProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;