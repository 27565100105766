import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Outlet, useNavigate } from "react-router-dom";
import { getMenus } from "../../src/api/menus";
import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
// import useTheme from "../hooks/useTheme";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { spacing } from "@mui/system";
import { THEMES } from "../constants";

// import dashboardItems from "../components/sidebar/dashboardItems";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    // width: ${drawerWidth}px;
    width: 0px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const HomeLayout = ({ children }) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      var token = window.localStorage.getItem("accessToken");

      if (token) {
        let menuData = await getMenus(token);
        let menu = menuData.data.data;
        setMenuItems(transform_obj(menu));

        let acesso = verificaAcesso(menu); 
        if(!acesso)
          navigate("/404")
      } 

    } catch (err) {
      console.error("não autorizado");
    }
  }

  //muda o nome das propriedados do objeto para se adequar ao formato esperado pelo componente
  function transform_obj(nodes) {
    var obj = [];

    for (var key in nodes) {
      var new_nodes = {};
      for (var prop in nodes[key]) {
        if (prop == 'itens') {
          new_nodes['pages'] = transform_obj(nodes[key][prop]);
        }
        else if (prop == 'subItens') {
          new_nodes['children'] = transform_obj(nodes[key][prop]);
        }
        else if (prop == 'titulo') {
          new_nodes['title'] = nodes[key][prop];
        }
        else if (prop == 'icone') {
          new_nodes['icon'] = nodes[key][prop];
        }
        else if (prop == 'url') {
          new_nodes['href'] = nodes[key][prop];
        }
        else {
          new_nodes[prop] = nodes[key][prop];
        }
      }

      obj.push(new_nodes);
    }
    return obj;
  }

  const verificaAcesso = (ob) => {
    let temAcesso = false;
    let path = window.location.pathname;

    if(path == "/"){
      temAcesso = true;
      return temAcesso;
    }

    var split = path.split('/');
    if(`/${split[1]}` === '/pedidos'){
      path = `/${split[1]}/${split[2]}`;
    }
    else if(`/${split[1]}` === '/devolucoes'){
      path = `/${split[1]}/${split[2]}`;
    } 
    else {
      path = `/${split[1]}`;
    }

    temAcesso = verificaUrl(ob, path);

    return temAcesso;
  };

  const verificaUrl = (ob, path) => {
    // debugger;
    let temAcesso = false;

    ob.map(obj => {
      // debugger;
      obj.itens.map(item => {
        // debugger;
        if(item.url){
          if(item.url == path){
            temAcesso = true;
            return;
          }
        }
        if(item.subItens){
          item.subItens.map(subItem => {
            if(subItem.url == path){
              temAcesso = true;
              return;
            }
          });
        }
      })
    })

    return temAcesso;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const { theme } = useTheme(theme);
  // const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  // const isLgUp = useMediaQuery((props) => props.theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        {/* <Box sx={{ display: { xs: "block", lg: "none" } }}> */}
        <Box>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={menuItems}
          />
        </Box>
        {/* <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Box> */}
      </Drawer>
      {/* <MuiThemeProvider theme={useTheme(theme)}> */}
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={12}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
      {/* </MuiThemeProvider> */}
    </Root>
  );
};

export default HomeLayout;