import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

function FeedbackDialog({ open, onClose, sucesso, error, mensagem, closeBtnLabel, closeBtnAction }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='alert-dialog'
        >
            {(open && sucesso) && (
                <div>
                    <DialogContent
                        sx={{
                            textAlign: 'center',
                            margin: 10
                        }}
                    >
                        <CheckCircleOutlineIcon
                            color='success'
                            sx={{
                                fontSize: 100,
                                marginBottom: 2
                            }}
                        />
                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
                            <span sx={{ fontSize: 32 }}>
                                {mensagem}
                            </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={closeBtnAction ? closeBtnAction : onClose}
                        >
                            {closeBtnLabel ? closeBtnLabel : 'Fechar'}
                        </Button>
                    </DialogActions>
                </div>
            )}

            {(open && error) && (
                <div>
                    <DialogContent
                        sx={{
                            textAlign: 'center',
                            margin: 10
                        }}
                    >
                        <CancelOutlinedIcon
                            color='error'
                            sx={{
                                fontSize: 100,
                                marginBottom: 2
                            }}
                        />
                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
                            Ocorreu um erro<br />
                            {error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>
                            Fechar
                        </Button>
                    </DialogActions>
                </div>
            )}
        </Dialog>
    );
}

export default FeedbackDialog; 