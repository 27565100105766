import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import { recuperacaoSenhaUsuario } from "../../api/usuarios";
import {
  Alert as MuiAlert,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import FeedbackDialog from "../dialogs/FeedbackDialog";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function EsqueciMinhaSenha() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const response = await recuperacaoSenhaUsuario(values)
      setSucesso(response.sucesso);

      setStatus({ sent: true });
      setSubmitting(false);
      openAlert();
    } catch (error) {
      setError(error.notificacoes[0].message);
      openAlert();
    }
  }

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const openAlert = () => {
    setOpen(true);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("E-mail inválido").max(255).required("Campo obrigatório"),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <React.Fragment>
          <FeedbackDialog
            open={open}
            onClose={handleClose}
            sucesso={sucesso}
            error={error}
            mensagem={
              <>
                <span>
                  Enviamos um e-mail com as instruções de como alterar a sua senha.
                </span>
                <br />
                <span>Não recebeu o e-mail?</span>
                <br /> <br />
                <ul style={{ textAlign: 'left' }}>
                  <li>Verifique a sua caixa de spam e/ou lixeira;</li>
                  <li>O endereço de e-mail informado pode ter sido digitado com erros;</li>
                  <li>Certifique-se que o endereço de e-mail informado possui cadastro no nosso sistema.</li>
                </ul>
                <span>
                  Se você ainda estiver com problemas para acessar a sua conta, contate o nosso suporte.
                </span>
              </>
            }
            closeBtnLabel="Voltar para tela de login"
            closeBtnAction={() => navigate("/login")}
          />

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={2}>
                <div>
                  <Typography variant="h3" gutterBottom>
                    Esqueci minha senha
                  </Typography>
                  <Typography sx={{ marginBottom: 5, marginTop: 5 }}>
                    Digite o seu e-mail para receber as instruções de definição de nova senha.
                  </Typography>
                </div>
                <TextField
                  type="email"
                  name="email"
                  label="E-mail"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Solicitar nova senha
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    component={Link}
                    to="/login"
                    color="primary"
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </React.Fragment>
      )}
    </Formik>
  );
}

export default EsqueciMinhaSenha;